import { NgModule }             from '@angular/core';

import {
  RouterModule,
  Routes,
  Router,
  ActivatedRoute,
  NavigationEnd,
}                                 from '@angular/router';

import { HomeComponent }          from './home.component';
import { TagComponent }           from './tag.component';
import { SearchComponent }        from './search.component';
import { UserComponent }          from './user.component';
import { MapComponent }           from './map/map.component';
import { SoundDetailComponent }   from './sound-detail.component';
import { UsersComponent }         from './users.component';
import { ChatterComponent }       from './chatter.component';
import { NotificationsComponent } from './notifications.component';
import { AvatarsComponent }       from './avatar/avatars.component';
import { BadgesComponent }        from './badges.component';
import { MeComponent }            from './me/me.component';

// static content
import { HelpComponent }        from './help.component';
import { DownloadComponent }    from './download.component';
import { TermsComponent }       from './terms.component';
import { PrivacyComponent }     from './privacy.component';

// mp3 shopping cart
import { CatalogComponent }     from './store/catalog/catalog.component';
import { CheckoutComponent }    from './store/cart/checkout.component';
import { OrdersComponent }      from './store/orders/orders.component';

// account mgt
import { DeleteComponent } from './account/delete.component';
import { SettingsComponent } from './account/settings.component';

// debugging
import { TokenComponent } from './token.component';

// rxjs
import { filter } from 'rxjs/operators';

// environment
import { environment } from '../environments/environment';

// declare google analytics global var
declare var ga : any;

const routes: Routes = [
  { path: '',                         component: HomeComponent },
  { path: 'sound/:slug',              component: SoundDetailComponent },
  { path: 'tag/:tag',                 component: TagComponent },
  { path: 'search',                   component: SearchComponent },
  { path: 'search/:search',           component: SearchComponent },
  { path: 'user/:user',               component: UserComponent },
  { path: 'app',                      component: DownloadComponent },
  { path: 'chatter',                  component: ChatterComponent },
  { path: 'map',                      component: MapComponent },
  { path: 'users',                    component: UsersComponent },
  { path: 'notifications',            component: NotificationsComponent },
  { path: 'avatars',                  component: AvatarsComponent },
  { path: 'badges',                   component: BadgesComponent },
  { path: 'help',                     component: HelpComponent },
  { path: 'terms',                    component: TermsComponent },
  { path: 'privacy',                  component: PrivacyComponent },
  { path: 'token/:token',             component: TokenComponent },
  { path: 'store',                    component: CatalogComponent},
  { path: 'store/checkout',           component: CheckoutComponent },
  { path: 'store/orders',             component: OrdersComponent },
  { path: 'store/orders/:orderId',    component: OrdersComponent },
  { path: 'account/delete',           component: DeleteComponent },
  { path: 'me',                       component: MeComponent },
  
  // redirect to home page (ex: /catalog or /categories from old site)
  { path: '**', redirectTo: '' }
];

@NgModule({
  imports: [ RouterModule.forRoot(routes) ],
  exports: [ RouterModule ]
})

export class AppRoutingModule
{
  logging: boolean = false;

  constructor(router: Router, activatedRoute: ActivatedRoute)
  {
    this.logging = !environment.production;

    // monitor page changes through NavigationEnd events (old way of doing this)    
    const navEnd = router.events.pipe(filter(e => e instanceof NavigationEnd));
    navEnd.subscribe((event) => {

        // send google analytics page view
        var url = (event as NavigationEnd).urlAfterRedirects;
        if (url)
        {
          // Old Google Analytics
          // ga('send', 'pageview', url);
                    
          /*
          window['ramp']['que'].push(() => {
            console.log("Playwire Destroy Units");
            window['ramp'].destroyUnits("all").catch((e: any) => {
        
                  console.warn("Playwire Error:", e);      
            });
          */
          
          // PlayWire set new path
          if (this.logging) console.log("Playwire Set Path:", url);          
          window['ramp']['que'].push(() => {
            window['ramp'].setPath(url).catch((e: any) => {        
                  console.warn("Playwire Error:", e);      
            });
          });          

          // scroll to the top (most pages do this automatically due to loading content async, but static pages do not)
          if (url.indexOf('#') < 0)
          {
            let cdkScrollable = document.querySelector('[cdkscrollable]');
            if (cdkScrollable) cdkScrollable.scrollTop = 0;
              else window.scrollTo(0,0);
          }
        }
    });
  }
}
