import { Component, AfterViewInit, Input } from '@angular/core'
import { MarketAuth } from './marketauth.service';
import { environment } from '../environments/environment';


@Component({
    selector: 'ad-playwire',
    template: `
    <div *ngIf="showAd" class="ad-container">
        <div [attr.data-pw-desk]="type" [attr.id]="selectorId" [attr.class]="cssClass"></div>
    </div>`,
    styleUrls: [ './ad-playwire.component.css' ],
})
export class AdPlayWireComponent implements AfterViewInit 
{
    public showAd: boolean = true;
    public logging: boolean = false;
    @Input() type: string | undefined;
    @Input() selectorId: string | undefined;    

    static counter : number = 0;

    constructor(protected marketAuth: MarketAuth)
    {        
        this.logging = !environment.production;
    }

    public ngOnInit(): void 
    {
        if (this.type != undefined)
        {
            // setup a unique identifer for ad unit type
            if (this.selectorId == undefined)
            {
                AdPlayWireComponent.counter++;
                this.selectorId = "desk_" + this.type + "_" + AdPlayWireComponent.counter.toString();
            }
            let user = this.marketAuth.getUser();
            let subscriber = user && user.isSubscriber;
            this.showAd = !subscriber;
            if (this.logging) console.log("Playwire Component Init - Id:", this.selectorId, "Show:", this.showAd);            
        }
        else
        {
            console.warn("Playwire Ad Not Configured");
        }
    }
    
    ngAfterViewInit() 
    {           
        //window['ramp']['que'].push(()=>{ window['ramp'].addTag("desk_lead_atf") });
        //console.log("Loaded PlayWire Leaderboard Ad", window['ramp']['que']);
    
        if (this.showAd && this.type != undefined && this.selectorId != undefined)
        {            
            window['ramp']['que'].push(() => {

                if (this.logging) console.log("Playwire Add Unit Id:", this.selectorId, "Type:", this.type);
                window['ramp'].addUnits([
                  {
                    type: this.type,
                    selectorId: this.selectorId
                  }
                ]).catch((e: any) => {
          
                    console.warn("Playwire Error:", e);
          
                }).finally(() => {
          
                    if (this.logging) console.log("Playwire Display Units Id:", this.selectorId, "Type:", this.type);
                    window['ramp'].displayUnits();
                });
              });
        }        
    }

    ngOnDestroy() 
    {
        // Perform cleanup logic here
        if (this.logging) console.log("Playwire Component Destroyed - Id:", this.selectorId);

        // PlayWire destroy ad units and set new path
        if (this.showAd && this.type != undefined && this.selectorId != undefined)
        {          
            window['ramp']['que'].push(() => {
            if (this.logging) console.log("Playwire Destroy Unit Type:", this.type);
            window['ramp'].destroyUnits(this.type).catch((e: any) => {
        
                  console.warn("Playwire Error:", e);      
            });
          });
        }          
    }
}
