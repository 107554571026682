import { Component, OnInit, ElementRef, ChangeDetectorRef } from '@angular/core';
import { User } from './user';
import { MarketService } from './market.service';
import { MetaService } from './meta.service';
import { Router, ActivatedRoute, Params }   from '@angular/router';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

@Component({
  selector: 'market-users',
  templateUrl: './users.component.html',
  styleUrls: [ './users.component.css' ],
})

// display top users based on zen
export class UsersComponent implements OnInit
{
  // information displayed in tiles html
  title = "Top Users";           // optional title displayed at top of page
  subtitle = "Highest Ranking White Noise Contributors";  // optional subtitle displayed at top of page  
  description: string;           // optional description displayed at top of page  
  users: User[] = [];            // users displayed on page 
  working = false;               // displays working progress bar if true

  // custom sort
  sort = "";  
  selectedTab = 0;
  private limit = 100;     // total items requested for query     

  constructor(protected marketService: MarketService,
              protected metaService: MetaService,
              protected router: Router,
              protected route: ActivatedRoute,
              private sanitizer: DomSanitizer)
  {
  } 

  public ngOnInit(): void
  {
    // update meta data
    this.metaService.updateMetadata(this.title, this.subtitle, "https://img.tmsoft.com/market/users.jpg");
    // request top users
    this.getUsers();
  }

  // call to reset internals for brand new query
  protected reset()
  {      
    this.limit = 100;      
    this.users = [];
    this.working = false;
  }

  setTab(index: number)
  {
    this.selectedTab = index;       
    this.getUsers();
  }

  // request top users
  protected getUsers(): void
  {    
    this.reset();
    this.working = true;            
    let filter = 0;
    switch (this.selectedTab) {
      case 0: filter = 2; break; // subscribers only
      case 1: filter = 0; break; // all users no admins
      case 2: filter = 1; break; // everyone
      default: filter = 0;
    }

    // default implement runs queries for users
    console.info("Requesting top users limit:", this.limit, "sort:", this.sort, "filter:", filter);
    this.marketService.getUsers(this.sort, filter, this.limit)
                      .then(users => this.addUsers(users))
                      .catch(err => this.handleError(err));
  }

  // received new data from server
  private addUsers(users : User[]): void
  {        
    this.users = users;
    console.log("Updated users: " + this.users.length);

    this.working = false;    
  }

  // called from html
  public lazyLoaded(user: User): void
  {
    /*
    // check if we have the minimum number of data and have loaded the last sound
    if (this.users.length > this.min && user == this.users[this.users.length - 1])
    {
      // request more data
      this.getUsers();
    }
    */
  }

  userLink(user: User) : string
  {
    return "/user/" + user.userId;
  }

  safeUserLink(user: User) : SafeUrl
  {
     // create a URL to the user
     return this.sanitizer.bypassSecurityTrustResourceUrl( this.userLink(user) );
  }

  public gotoUser(user : User): void
  {
    console.log("Navigate to user " + user.displayName + " with uid " + user.userId);
    var url = this.userLink(user); // "/sound/" + sound.Slug + "?id=" + sound.Uid;
    this.router.navigateByUrl(url);    
  }

  avatarError(image : any): void
  {
    //console.warn("Failed to load sound avatar");
    image.onerror = null;
    image.src = "/assets/images/default_user.jpg";
  }

  private handleError(error : any) : void
  {
    console.info("Server had error on sound request.");
    // do not permit anymore requests
    this.working = false;    
  }
}
