import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd }   from '@angular/router';
import { Location, formatNumber }   from '@angular/common';

import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from "@angular/material/legacy-dialog";

import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';

import { Sound } from './sound';
import { MarketService } from './market.service';
import { MetaService } from './meta.service';

import { Tag } from './tag';
import { Flags, FlagResult } from './flag';
import { SoundDownloadDialog } from './sound-download.dialog';
import { CartAddDialog } from './store/cart/cart-add.dialog';

import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { MarketAuth } from './marketauth.service';

@Component({
  selector: 'sound-detail',
  templateUrl: './sound-detail.component.html',
  styleUrls: [ './sound-detail.component.css' ],
})

export class SoundDetailComponent implements OnInit
{
  constructor(
    private marketService: MarketService,
    private marketAuth: MarketAuth,
    private route: ActivatedRoute,
    private router: Router,
    private location: Location,
    private metaService: MetaService,
    private dialog: MatDialog,
    private sanitizer: DomSanitizer,
    private snackBar: MatSnackBar
  )
  { }

  sound: Sound;
  tags: Tag[] = [];
  flags: Flags;
  working = false;
  previewLink: string;
  youTubeLink: SafeUrl;
  similarSounds: Sound[] = null;
  tabIndex = 0;

  followFlag = 1;
  isFollowed = false;

  ngOnInit(): void
  {
    var combine = combineLatest(this.route.params, this.route.queryParams).pipe(map(results => ({params: results[0], query: results[1]})))

    // subscribe to both router params (slug) and query params (id)
    combine.subscribe( ap => {
        console.log("Received Slug: " + ap.params['slug'] + " ID: " + ap.query['id']);
        this.working = true;
        this.marketService.getSound(ap.query['id'], ap.params['slug'])
                         .then( sound => { this.setSound(sound); } )
                         .catch( err => { this.handleError(err); });
      });

    // single route param subscribe:
    // this.route.params
    //  .switchMap((params: Params) => this.marketService.getSound(params['id']))
    //  .subscribe(sound => this.sound = sound);

    //this.router.events.subscribe((event) => {
    //  if (event instanceof NavigationEnd) {
    //    console.log("Scrolling to top");
    //    window.scrollTo(0, 0);
    //  }
    //});
  }

  setSound(sound : Sound): void
  {
    this.working = false;
    this.sound = sound;

    if (this.sound.DisplayName == null || this.sound.DisplayName.length == 0)
    {
      this.sound.DisplayName = "Anonymous";
    }

    // update the itunes url to apple music
    if (this.sound.ITunesUrl && this.sound.ITunesUrl.length > 0)
    {
      // ex: https://itunes.apple.com/album/id937298275?at=11l4Kt&ct=whitenoisemarket.com
      if (this.sound.ITunesUrl.indexOf("?") > 0)
      {
        this.sound.AppleMusicUrl = this.sound.ITunesUrl + "&app=music";
        this.sound.ITunesUrl = this.sound.ITunesUrl + "&app=itunes";
      }
      else
      {
        this.sound.AppleMusicUrl = this.sound.ITunesUrl + "?at=11l4Kt&ct=whitenoisemarket.com&app=music";
        this.sound.ITunesUrl = this.sound.ITunesUrl + "?at=11l4Kt&ct=whitenoisemarket.com&app=itunes";
      }
    }

    // override preview and download links to be absolute links (in case of different server)
    this.previewLink = this.marketService.previewLink(sound);
    this.youTubeLink = this.safeYouTubeLink(sound);
    // parse tags
    if (sound.Tags)
    {
      console.log("Parsing tags");
      for (let tag of sound.Tags.split(','))
      {
        this.marketService.getTag(tag).then( response => this.tags.push(response) );
      }
    }

    // pull in hearts
    this.marketService.getFlags(1, this.sound.Uid)
                  .then( flags => { this.setHearts(flags); } )
                  .catch( err => { this.logError(err); });

    // remove this code as this is just to get local server to work correctly
    // sound.PreviewUrl = "http://whitenoisemarket.com/preview/" + sound.Uid;

    const title = sound.Label;
    const description = sound.Description + " - " + "Download " + sound.Label + " to White Noise for iOS, Android, Mac, and Windows for free.";
    const image = sound.ImageUrl;

    // update title and meta data
    this.metaService.updateMetadata(title, description, image);
  }

  logError(error : any) : void
  {
      console.warn("Error: " + error);
  }

  goBack(): void
  {
    this.location.back();
  }

  avatarError(image : any): void
  {
    //console.warn("Failed to load sound avatar");
    image.onerror = null;
    image.src = "/assets/images/default_user.jpg";
  }

  download(): void
  {
    // try getting a download link (checks for authentication and if authentication is not required for original sounds)
    var downloadUrl = this.marketService.downloadLink(this.sound);
    if (downloadUrl)
    {
      var dialogRef = this.dialog.open(SoundDownloadDialog);
      dialogRef.afterClosed().subscribe(result =>
      {
        console.log('Dialog Result: ' + result);
        this.marketService.trackSoundDownload(this.sound);
        this.sound.Stats.Downloads += 1;
      });
      dialogRef.componentInstance.sound = this.sound;
    }
    else if (this.marketAuth.isAuthenticated() == false)
    {
       // login to download
       this.marketAuth.showLogin();
    }
    else
    {
      console.error("Sound has no download link!");
    }
  }

  // hearts
  setHearts(flags : Flags): void
  {
    if (flags == null) return;

    // set flags which comments will receive as input for updating comment hearts
    this.flags = flags;

    // the empty string represents the user heart for sound (otherwise its a comment heart)
    this.sound.UserHeart = flags[""];
    this.refreshFollow();
  }

  toggleHeart(): void
  {
    console.log("Toggle sound heart");

    this.marketService.toggleFollow(this.sound, this.followFlag)
        .then(reuslt => this.updateHeart(reuslt))
        .catch(err => this.logError(err));

    /*
    if (this.marketAuth.isAuthenticated() == false)
    {
        this.marketAuth.showLogin();
        return;
    }
      
    const value = !this.sound.UserHeart;
    this.marketService.setFlag(1, this.sound.Uid, "", !this.sound.UserHeart)
                     .then( result => { this.updateHeart(result); })
                     .catch( err => { this.logError(err); });
    */
  }

  updateHeart(result : FlagResult)
  {
    if (result.Success)
    {
      this.sound.UserHeart = result.Value > 0;
      this.sound.Stats.Hearts = result.Total;
      this.refreshFollow();
    }
  }

  refreshFollow()
  {
    this.isFollowed = this.marketService.isFollowed(this.sound, this.followFlag);
  }

  showComments()
  {
    // the next line is required to work around a bug in WebKit (Chrome / Safari)
    var comments = document.getElementById("marketComments");
    if (comments != null)
    {
      // bug: this was scrolling the tag list instead of the main window
      // var y = comments.offsetTop;
      // console.log("Scrolling to comments at pos", y);
      // let cdkScrollable = document.querySelector('[cdkscrollable]');
      // if (cdkScrollable) cdkScrollable.scrollTop = y;
      //   else window.scroll(0, y);

      // fix: easier to scroll comment element into view
      comments.scrollIntoView();
    }
  }

  showMinutes()
  {
    console.log("Showing Minutes")
    if (this.sound && this.sound.Stats && this.sound.Stats.Minutes > 0)
    {
      var minutes = this.sound.Stats.Minutes;
      var msg = "Sound has been streamed for total of " + formatNumber(minutes, "en-US") + " minutes.";

      // add commas to minutes.  convert to hours, days, months, years
      if (minutes >= 60 * 24 * 365) // years
      {
          var num = minutes / (60.0 * 24.0 * 365.0);
          msg += " That's " + formatNumber(num, "en-US") + " years of streaming!!!";
      }
      else if (minutes >= 60 * 24) // days
      {
          var num = minutes / (60.0 * 24.0);
          msg += " That's " + formatNumber(num, "en-US") + " days of streaming!";
      }
      else if (minutes >= 60) // hours
      {
          var num = minutes / (60.0);
          msg += " That's " + formatNumber(num, "en-US") + " hours of streaming.";
      }

      this.snackBar.open(msg, null, { duration: 3000, verticalPosition: "bottom" });
    }
  }

  addCartDialog()
  {
    var dialogRef = this.dialog.open(CartAddDialog);
    dialogRef.componentInstance.sound = this.sound;
    dialogRef.afterClosed().subscribe(result =>
    {
      if (result && result.value)
      {
        let duration = result.value as number;
        if (duration > 0)
        {
          let hours = duration / 60 / 60;
          let msg = this.sound.Label + " ("+ hours + (hours==1 ? " hour" : " hours") + ") added to cart.";
          this.snackBar.open(msg, "View Cart", { duration: 5000, verticalPosition: "top" })
                       .onAction().subscribe(()=> {
                         // navigate to cart
                         this.router.navigateByUrl('/store/checkout');
                        });
        }
      }
    });
  }

  trackStoreClick(store: string)
  {
    this.marketService.trackStoreClick(store, this.sound);
  }

  safeImageLink(sound: Sound) : SafeUrl
  {
     // create a URL to the sound
     let url = this.marketService.imageLink(sound.ImageUrl, 0);
     return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  safeYouTubeLink(sound: Sound) : SafeUrl
  {
     // create a URL to the sound
     if (sound.YouTubeUrl == null) return null;
     var n = sound.YouTubeUrl.lastIndexOf("="); // ex: https://www.youtube.com/watch?v=G4nxZHwHZg8
     if (n <= 0) n = sound.YouTubeUrl.lastIndexOf("/"); // ex: https://youtu.be/G4nxZHwHZg8
     if (n <= 0) return null;
     var videoid = sound.YouTubeUrl.substr(n+1);
     var url = "https://www.youtube.com/embed/" + videoid + "?rel=0&showinfo=0";
     return this.sanitizer.bypassSecurityTrustResourceUrl( url );
  }

  soundLink(sound: Sound) : string
  {
    return "/sound/" + sound.Slug + "?id=" + sound.Uid;
  }

  safeSoundLink(sound: Sound) : SafeUrl
  {
     // create a URL to the sound
     return this.sanitizer.bypassSecurityTrustResourceUrl( this.soundLink(sound) );
  }

  public gotoSound(sound : Sound): void
  {
    // navigating to a sound using router which reuses this component so we have to null everything out
    this.sound = null;
    this.tags = [];
    this.flags = null;
    this.working = false;
    this.previewLink = "";
    this.youTubeLink = null;
    this.similarSounds = null;
    this.tabIndex = 0;

    // now navigate to the sound using the router so a full page reload is not performed
    console.log("Navigating to sound " + sound.Label + " with uid " + sound.Uid);
    var url = this.soundLink(sound); // "/sound/" + sound.Slug + "?id=" + sound.Uid;
    this.router.navigateByUrl(url);
    
  }

  onTabChange($event: any) : void
  {
    let clickedIndex = $event.index;
    console.log("Tab Change: ", clickedIndex);
    if (clickedIndex == 1 && this.similarSounds == null) // similar sounds
    {      
      this.marketService.getRecommend(this.sound.Uid)
      .then( result => { this.updateRecommend(result); } )
      .catch( err => { this.logError(err); });
    }    
  }

  updateRecommend(result : Sound[]) : void
  {
    console.log("Recommend results: ", result);
    this.similarSounds = result
  }

  handleError(error : any) : void
  {
      console.warn("No sound details Found: " + error);
      this.sound = null;
      this.working = false;
  }
}